import React from "react";
import { StyledCallFor, StyledCallForItem, StyledCallForItems } from "./style";
import waveSrc from "../../../static/2020/images/impactcall/wave-call-for.png";
import waveDesktopSrc from "../../../static/2020/images/impactcall/wave-call-for-desktop.png";
import waveBottomSrc from "../../../static/2020/images/impactcall/wave-call-for-bottom.png";
import waveBottomDesktopSrc from "../../../static/2020/images/impactcall/wave-call-for-bottom-desktop.png";
import mobile0Src from "../../../static/2020/images/impactcall/call-for-mobile-0.png";
import mobile1Src from "../../../static/2020/images/impactcall/call-for-mobile-1.png";
import mobile2Src from "../../../static/2020/images/impactcall/call-for-mobile-2.png";
import desktopSrc from "../../../static/2020/images/impactcall/call-for-space.png";

export const CallFor = () => (
  <>
    <img src={waveSrc} className="mobileOnly" loading="lazy" />
    <img src={waveDesktopSrc} className="desktopOnly" loading="lazy" />
    <StyledCallFor id="contest">
      <h2>
        OSCAR DELLE API
        <br />
        1°Edizione<span> - </span>Italy 22
      </h2>
      <p>
        Per partecipare al premio per "OSCAR DELLE API" non importa chi sei e da
        dove vieni, ma devi saper curare le api e avere almeno un alveare.
        Vogliamo premiare gli apicoltori e le loro api, valorizzando la simbiosi tra uomo e ape.
        Iscriviti gratuitamente entro il 10 Maggio 2022.
      </p>
      <strong>
        Ci saranno 3 categorie di premi. Potrai partecipare in 1 o in tutte e 3:
      </strong>
      <img src={desktopSrc} className="desktopOnly" loading="lazy" />
      <StyledCallForItems>
        <StyledCallForItem>
          <img src={mobile0Src} className="mobileOnly" loading="lazy" />
          <h3>OSCAR <br /> Biodiversità</h3>
          <p>
            L'apiario perfetto non è solo l'organizzazione degli alveari, ma soprattutto il luogo.
            Quanta più biodiversità è presente e quanti più pascoli ci sono, tanto più sarà la ricchezza a cui le api potranno
            attingere. Raccontaci con una foto l'apiario perfetto.
          </p>
        </StyledCallForItem>
        <StyledCallForItem>
          <img src={mobile1Src} className="mobileOnly" loading="lazy" />
          <h3>
            OSCAR <br /> La perfezione del telaino
            <br />

          </h3>
          <p>
            Il telaio perfetto mostra le giuste proporzioni fra covata, miele e polline.
            La covata compatta è segno di una regina performante e in salute, la presenza di api di un alveare forte e numeroso,
            l'assenza di deformazioni e di cera giovane segno di cura e di attenzione per le proprie api.
            Raccontacelo con una foto.
          </p>
        </StyledCallForItem>
        <StyledCallForItem>
          <img src={mobile2Src} className="mobileOnly" loading="lazy" />
          <h3>OSCAR <br />L'essenza dell'apicoltore</h3>
          <p>
            L'apicoltore instaura un legale unico con le proprie api e i propri apiari.
            Raccontacelo con una foto che descriva l'essenza del tuo essere apicoltore e del tuo modo di vivere l'apicoltura.
          </p>
        </StyledCallForItem>
      </StyledCallForItems>
    </StyledCallFor>
    <img src={waveBottomSrc} className="mobileOnly" loading="lazy" />
    <img src={waveBottomDesktopSrc} className="desktopOnly" loading="lazy" />
  </>
);
